import React from 'react'

import { ExternalFont } from './external-font'

export function Fonts() {
  return (
    <>
      <ExternalFont href="https://fonts.googleapis.com/css?family=Poppins:400,600,700,800&display=swap" />
      <ExternalFont
        href="https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap"
        rel="stylesheet"
      />
    </>
  )
}

// for @loadable/components
export default Fonts
